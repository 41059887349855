
import { defineComponent, onMounted, inject, watch, ref, reactive } from "vue";
import dateFormat from "../../utils/dateFormat";
import { calendarFormat} from "@/utils/dateFormat";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";


export default defineComponent({
  name: "InvoiceItem",
  props: {
    prize: Object
  },
  
  setup(props) {
    const prizeData = reactive((props.prize as any).data || [])
    function neiapplyExpressTrace(piaopost: string,piaomail: string, index: any){
      const formData = new FormData();
      formData.append("piaopost", piaopost.valueOf());
      formData.append("piaomail", piaomail.valueOf());
      axios
        .post('/M/Server/neiApplyExpressTrace', formData, {params: { validate: true }})
        .then((res) => {
          if (res.data.success) {
            prizeData[index].traces = res.data.obj
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: "系统出错，请联系管理员!",
          });
        });
    }


    return {
      dateFormat,
      calendarFormat,
      neiapplyExpressTrace,
      prizeData
    };
  }
});
