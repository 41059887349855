<template>
  <main>
    <list url="/M/AokeNei/GetUserPrizes" :show="true">
      <template v-slot:default="data">
        <prize-item :prize="data"></prize-item>
      </template>
    </list>
  </main>
</template>


<script lang="ts">
import { defineComponent, ref } from "vue";
import { calendarFormat } from "@/utils/dateFormat";
import list from "@/components/common/list.vue";
import PrizeItem from "@/components/common/PrizeItem.vue";
export default defineComponent({
  name: "Prize",
  components:{
    list,
    PrizeItem
  },
  setup() {

    return {
      calendarFormat,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 110px;
  // background-color: #f4f4f4;
}

.notice-wapper {
  margin: 0 auto;
  li {
    padding: 15px 10px;
    box-sizing: border-box;
    position: relative;
    padding-left: 80px;
    border-bottom: 6px solid #f4f4f4;

    .notice-date {
      color: #999999;
      font-size: 14px;
    }

    .notice-content {
      font-size: 15px;
      line-height: 25px;
      color: #444444;
      margin-top: 7.5px;
    }

    .order-ul {
      li {
        box-sizing: border-box;
        padding: 0 15px;
        padding-bottom: 15px;
        overflow: hidden;
        border-bottom: 1px solid #f4f4f4;
        position: relative;
        padding-top: 15px;
      }
    }
    .spend-date {
      position: absolute;
      // left: 7.5px;
      // top: 24px;
      left: 17px;
      top: 51px;
      color: #666;
      span {
        font-size: 22px;
        text-align: center;
        display: block;
      }
      p {
        font-size: 13.95px;
        border-top: 1px solid #e5e5e5;
        padding-top: 5px;
      }
    }
    .price {
      position: absolute;
      right: 0;
      top: 0;
      height: 40.012px;
      line-height: 40.012px;
      color: rgb(102, 102, 102);
    }
    .order-date {
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 5px 0 5px 0;
      padding-left: 10px;
      font-size: 16.5px;
      box-sizing: border-box;
      color: #666;
      position: relative;
    }
    .order-btn {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -11.25px;
      text-align: center;
      width: 80px;
      height: 22.5px;
      border-radius: 15px;

      line-height: 22.5px;

      font-size: 14px;
      color: #b9135a;
      border: solid 1px #b9135a;
    }
  }
}
.order-logistics{
  display: block;
  width: 80px;
  height: 25px;
  background: #ffeded;
  text-align: center;
  color: #b9135a;
  line-height: 25px;
  /* float: right; */
  margin-top: 10px;
  border: 1px solid #b9135a;
  border-radius: 20px;
  font-size: 14px;
}
.logistics-notyet{
  font-size: 16px!important;
  color:#b9135a;
}
.nei-order-wuliu ul li{
  line-height: 20px;
}
.orderDetail_accept {
  font-size: 14px;
  color: #999;
}
</style>